import { createAction } from "@ngrx/store";
import { ActionCreator, TypedAction } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import {
  IDashboardList,
  IListData,
  IListIdWithSelectedRecordType,
} from "@shared/interfaces/list";
import { IProductsResponse, IProductRequest } from "@shared/interfaces/product";
import { IServerError } from "@shared/interfaces/server-error";
import {
  IListAssign,
  IProductNavigationPayload,
  IRenameListPayload,
} from "../../../dashboard/interfaces";
import {
  IChangePagePayload,
  ICDLListChangeStatusPayload,
  IDashboardDeleteEntity,
  IExportDashboardList,
} from "../../interfaces";
import { IListCalculatedData } from "../../interfaces/calculate-list";

enum ACTION_TYPES {
  GET_DEPLOY_ONLY_LISTS = "[DASHBOARD DEPLOY ONLY LISTS]: get lists",
  GET_DEPLOY_ONLY_LISTS_ERROR = "[DASHBOARD DEPLOY ONLY LISTS]: get lists error",
  GET_DEPLOY_ONLY_LISTS_SUCCESS = "[DASHBOARD DEPLOY ONLY LISTS]: get lists success",

  TO_NEXT_DEPLOY_ONLY_LISTS_PAGE = "[DASHBOARD DEPLOY ONLY LISTS]: next page",
  TO_NEXT_DEPLOY_ONLY_LISTS_PAGE_SUCCESS = "[DASHBOARD DEPLOY ONLY LISTS]: next page success",

  CHANGE_PAGE_WITH_OR_WITHOUT_LOAD_DEPLOY_ONLY_DATA = "[DASHBOARD DEPLOY ONLY LISTS]: change page with or without load data",
  CHANGE_PAGE_WITHOUT_LOAD_DEPLOY_ONLY_DATA = "[DASHBOARD DEPLOY ONLY LISTS]: change page without load data",
  CHANGE_PAGE_DEPLOY_ONLY = "[DASHBOARD DEPLOY ONLY LISTS]: change current page",
  CHANGE_PAGE_DEPLOY_ONLY_SUCCESS = "[DASHBOARD DEPLOY ONLY LISTS]: change current page success",

  RELOAD_DEPLOY_ONLY_LISTS = "[DASHBOARD DEPLOY ONLY LISTS]: reload lists",
  RELOAD_DEPLOY_ONLY_LISTS_ERROR = "[DASHBOARD DEPLOY ONLY LISTS]: reload lists error",
  RELOAD_DEPLOY_ONLY_LISTS_SUCCESS = "[DASHBOARD DEPLOY ONLY LISTS]: reload lists success",

  DEPLOY_ONLY_LIST_NAVIGATE = "[DASHBOARD DEPLOY ONLY LISTS]: product navigate",
  DEPLOY_ONLY_LIST_NAVIGATE_CANCEL = "[DASHBOARD DEPLOY ONLY LISTS]: product navigate cancel",

  RENAME_DEPLOY_ONLY_LIST = "[DASHBOARD DEPLOY ONLY LISTS]: rename list",
  RENAME_DEPLOY_ONLY_LIST_ERROR = "[DASHBOARD DEPLOY ONLY LISTS]: rename list error",
  RENAME_DEPLOY_ONLY_LIST_SUCCESS = "[DASHBOARD DEPLOY ONLY LISTS]: rename list success",
  RENAME_DEPLOY_ONLY_LIST_CANCEL = "[DASHBOARD DEPLOY ONLY LISTS]: rename list cancel",

  DUPLICATE_DEPLOY_ONLY_LIST = "[DASHBOARD DEPLOY ONLY LISTS]: duplicate list",
  DUPLICATE_DEPLOY_ONLY_LIST_ERROR = "[DASHBOARD DEPLOY ONLY LISTS]: duplicate list error",
  DUPLICATE_DEPLOY_ONLY_LIST_SUCCESS = "[DASHBOARD DEPLOY ONLY LISTS]: duplicate list success",

  CREATE_CUSTOM_LIST = "[DASHBOARD DEPLOY ONLY LISTS]: Create custom list",
  CREATE_CUSTOM_LIST_ERROR = "[DASHBOARD DEPLOY ONLY LISTS]: Create custom list error",
  CREATE_CUSTOM_LIST_SUCCESS = "[DASHBOARD DEPLOY ONLY LISTS]: Create custom list success",

  SHOW_DELETE_DEPLOY_ONLY_LIST_POP_UP = "[DASHBOARD DEPLOY ONLY LISTS]: show delete list pop up",
  SHOW_DELETE_DEPLOY_ONLY_CUSTOM_POP_UP = "[DASHBOARD DEPLOY ONLY LISTS]: show delete custom list pop up",

  GO_TO_CUSTOM_LIST = "[DASHBOARD DEPLOY ONLY LISTS] go to custom list",

  DELETE_DEPLOY_ONLY_LIST = "[DASHBOARD DEPLOY ONLY LISTS]: delete list",
  DELETE_DEPLOY_ONLY_LIST_ERROR = "[DASHBOARD DEPLOY ONLY LISTS]: delete list error",
  DELETE_DEPLOY_ONLY_LIST_SUCCESS = "[DASHBOARD DEPLOY ONLY LISTS]: delete list success",

  ASSIGN_DEPLOY_ONLY_LIST = "[DASHBOARD DEPLOY ONLY LISTS] assign list",
  ASSIGN_DEPLOY_ONLY_LIST_ERROR = "[DASHBOARD DEPLOY ONLY LISTS] assign list error",
  ASSIGN_DEPLOY_ONLY_LIST_SUCCESS = "[DASHBOARD DEPLOY ONLY LISTS] assign list success",

  CALCULATE_UPDATE_DEPLOY_ONLY = "[DASHBOARD DEPLOY ONLY LISTS] Calculate update",
  CALCULATE_UPDATE_DEPLOY_ONLY_SUCCESS = "[DASHBOARD DEPLOY ONLY LISTS] Calculate update Success",

  EXPORT_DEPLOY_ONLY_LIST = "[DASHBOARD DEPLOY ONLY LISTS] export list",

  // SHOW_CDL_LIST_CHANGE_STATUS_POP_UP = "[DASHBOARD DEPLOY ONLY LISTS]: show cdl list change statuspop up",

  // CDL_LIST_CHANGE_STATUS = "[DASHBOARD DEPLOY ONLY LISTS]: cdl list change status",
  // CDL_LIST_CHANGE_STATUS_ERROR = "[DASHBOARD DEPLOY ONLY LISTS]: cdl list change status error",
  // CDL_LIST_CHANGE_STATUS_SUCCESS = "[DASHBOARD DEPLOY ONLY LISTS]: cdl list change status success",

  // CDL_LIST_REFRESH = "[DASHBOARD DEPLOY ONLY LISTS] cdl list refresh",
  // CDL_LIST_REFRESH_ERROR = "[DASHBOARD DEPLOY ONLY LISTS] cdl list refresh error",
  // CDL_LIST_REFRESH_SUCCESS = "[DASHBOARD DEPLOY ONLY LISTS] cdl list refresh success",

  LISTEN_SOCKET_COUNTS_REFRESHED = "[DASHBOARD DEPLOY ONLY LISTS]: listen socket counts refreshed",

  RESET_DEPLOY_ONLY_LISTS = "[DASHBOARD DEPLOY ONLY LISTS]: dashboard reset lists",
}

export const getDashboardDeployOnlyListsAction: ActionCreator<
  ACTION_TYPES.GET_DEPLOY_ONLY_LISTS,
  () => TypedAction<ACTION_TYPES.GET_DEPLOY_ONLY_LISTS>
> = createAction(ACTION_TYPES.GET_DEPLOY_ONLY_LISTS);
export const getDashboardDeployOnlyListsErrorAction: CreateActionType<
  ACTION_TYPES.GET_DEPLOY_ONLY_LISTS_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.GET_DEPLOY_ONLY_LISTS_ERROR,
  createPayload<IServerError>(),
);
export const getDashboardDeployOnlyListsSuccessAction: CreateActionType<
  ACTION_TYPES.GET_DEPLOY_ONLY_LISTS_SUCCESS,
  IProductsResponse
> = createAction(
  ACTION_TYPES.GET_DEPLOY_ONLY_LISTS_SUCCESS,
  createPayload<IProductsResponse>(),
);

export const getDashboardDeployOnlyListsNextPageAction: CreateActionType<
  ACTION_TYPES.TO_NEXT_DEPLOY_ONLY_LISTS_PAGE,
  IProductRequest
> = createAction(
  ACTION_TYPES.TO_NEXT_DEPLOY_ONLY_LISTS_PAGE,
  createPayload<IProductRequest>(),
);
export const getDashboardDeployOnlyListsNextPageSuccessAction: CreateActionType<
  ACTION_TYPES.TO_NEXT_DEPLOY_ONLY_LISTS_PAGE_SUCCESS,
  IProductsResponse
> = createAction(
  ACTION_TYPES.TO_NEXT_DEPLOY_ONLY_LISTS_PAGE_SUCCESS,
  createPayload<IProductsResponse>(),
);

export const changePageWithOrWithoutLoadDataAction: CreateActionType<
  ACTION_TYPES.CHANGE_PAGE_WITH_OR_WITHOUT_LOAD_DEPLOY_ONLY_DATA,
  IChangePagePayload
> = createAction(
  ACTION_TYPES.CHANGE_PAGE_WITH_OR_WITHOUT_LOAD_DEPLOY_ONLY_DATA,
  createPayload<IChangePagePayload>(),
);
export const changePageWithoutLoadDataDashboardDeployOnlyListsAction: CreateActionType<
  ACTION_TYPES.CHANGE_PAGE_WITHOUT_LOAD_DEPLOY_ONLY_DATA,
  number
> = createAction(
  ACTION_TYPES.CHANGE_PAGE_WITHOUT_LOAD_DEPLOY_ONLY_DATA,
  createPayload<number>(),
); // currentPage
export const changePageDashboardDeployOnlyListsAction: CreateActionType<
  ACTION_TYPES.CHANGE_PAGE_DEPLOY_ONLY,
  number
> = createAction(ACTION_TYPES.CHANGE_PAGE_DEPLOY_ONLY, createPayload<number>()); // currentPage
export const changePageDashboardDeployOnlyListsSuccessAction: CreateActionType<
  ACTION_TYPES.CHANGE_PAGE_DEPLOY_ONLY_SUCCESS,
  IProductsResponse
> = createAction(
  ACTION_TYPES.CHANGE_PAGE_DEPLOY_ONLY_SUCCESS,
  createPayload<IProductsResponse>(),
);

export const reloadDashboardDeployOnlyListsAction: ActionCreator<
  ACTION_TYPES.RELOAD_DEPLOY_ONLY_LISTS,
  () => TypedAction<ACTION_TYPES.RELOAD_DEPLOY_ONLY_LISTS>
> = createAction(ACTION_TYPES.RELOAD_DEPLOY_ONLY_LISTS);
export const reloadDashboardDeployOnlyListsErrorAction: CreateActionType<
  ACTION_TYPES.RELOAD_DEPLOY_ONLY_LISTS_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.RELOAD_DEPLOY_ONLY_LISTS_ERROR,
  createPayload<IServerError>(),
);
export const reloadDashboardDeployOnlyListsSuccessAction: CreateActionType<
  ACTION_TYPES.RELOAD_DEPLOY_ONLY_LISTS_SUCCESS,
  IProductsResponse
> = createAction(
  ACTION_TYPES.RELOAD_DEPLOY_ONLY_LISTS_SUCCESS,
  createPayload<IProductsResponse>(),
);

export const deployOnlyListNavigateAction: CreateActionType<
  ACTION_TYPES.DEPLOY_ONLY_LIST_NAVIGATE,
  IProductNavigationPayload
> = createAction(
  ACTION_TYPES.DEPLOY_ONLY_LIST_NAVIGATE,
  createPayload<IProductNavigationPayload>(),
);
export const deployOnlyListNavigateCancelAction: ActionCreator<
  ACTION_TYPES.DEPLOY_ONLY_LIST_NAVIGATE_CANCEL,
  () => TypedAction<ACTION_TYPES.DEPLOY_ONLY_LIST_NAVIGATE_CANCEL>
> = createAction(ACTION_TYPES.DEPLOY_ONLY_LIST_NAVIGATE_CANCEL);

export const renameDashboardDeployOnlyListAction: CreateActionType<
  ACTION_TYPES.RENAME_DEPLOY_ONLY_LIST,
  IRenameListPayload
> = createAction(
  ACTION_TYPES.RENAME_DEPLOY_ONLY_LIST,
  createPayload<IRenameListPayload>(),
);
export const renameDashboardDeployOnlyListErrorAction: CreateActionType<
  ACTION_TYPES.RENAME_DEPLOY_ONLY_LIST_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.RENAME_DEPLOY_ONLY_LIST_ERROR,
  createPayload<IServerError>(),
);
export const renameDashboardDeployOnlyListSuccessAction: CreateActionType<
  ACTION_TYPES.RENAME_DEPLOY_ONLY_LIST_SUCCESS,
  IListData
> = createAction(
  ACTION_TYPES.RENAME_DEPLOY_ONLY_LIST_SUCCESS,
  createPayload<IListData>(),
);
export const renameDashboardDeployOnlyListCancelAction: ActionCreator<
  ACTION_TYPES.RENAME_DEPLOY_ONLY_LIST_CANCEL,
  () => TypedAction<ACTION_TYPES.RENAME_DEPLOY_ONLY_LIST_CANCEL>
> = createAction(ACTION_TYPES.RENAME_DEPLOY_ONLY_LIST_CANCEL);

export const duplicateDashboardDeployOnlyListAction: CreateActionType<
  ACTION_TYPES.DUPLICATE_DEPLOY_ONLY_LIST,
  IDashboardDeleteEntity
> = createAction(
  ACTION_TYPES.DUPLICATE_DEPLOY_ONLY_LIST,
  createPayload<IDashboardDeleteEntity>(),
);
export const duplicateDashboardDeployOnlyListErrorAction: CreateActionType<
  ACTION_TYPES.DUPLICATE_DEPLOY_ONLY_LIST_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.DUPLICATE_DEPLOY_ONLY_LIST_ERROR,
  createPayload<IServerError>(),
);
export const duplicateDashboardSuccessListAction: ActionCreator<
  ACTION_TYPES.DUPLICATE_DEPLOY_ONLY_LIST_SUCCESS,
  () => TypedAction<ACTION_TYPES.DUPLICATE_DEPLOY_ONLY_LIST_SUCCESS>
> = createAction(ACTION_TYPES.DUPLICATE_DEPLOY_ONLY_LIST_SUCCESS);

export const createCustomListAction: CreateActionType<
  ACTION_TYPES.CREATE_CUSTOM_LIST,
  IListIdWithSelectedRecordType
> = createAction(
  ACTION_TYPES.CREATE_CUSTOM_LIST,
  createPayload<IListIdWithSelectedRecordType>(),
);
export const createCustomListErrorAction: CreateActionType<
  ACTION_TYPES.CREATE_CUSTOM_LIST_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.CREATE_CUSTOM_LIST_ERROR,
  createPayload<IServerError>(),
);
export const createCustomListSuccessAction: ActionCreator<
  ACTION_TYPES.CREATE_CUSTOM_LIST_SUCCESS,
  () => TypedAction<ACTION_TYPES.CREATE_CUSTOM_LIST_SUCCESS>
> = createAction(ACTION_TYPES.CREATE_CUSTOM_LIST_SUCCESS);

export const showDeleteListPopUpAction: CreateActionType<
  ACTION_TYPES.SHOW_DELETE_DEPLOY_ONLY_LIST_POP_UP,
  IDashboardDeleteEntity
> = createAction(
  ACTION_TYPES.SHOW_DELETE_DEPLOY_ONLY_LIST_POP_UP,
  createPayload<IDashboardDeleteEntity>(),
);
export const showDeleteCustomListPopUpAction: CreateActionType<
  ACTION_TYPES.SHOW_DELETE_DEPLOY_ONLY_CUSTOM_POP_UP,
  IDashboardDeleteEntity
> = createAction(
  ACTION_TYPES.SHOW_DELETE_DEPLOY_ONLY_CUSTOM_POP_UP,
  createPayload<IDashboardDeleteEntity>(),
);

export const goToCustomListAction: CreateActionType<
  ACTION_TYPES.GO_TO_CUSTOM_LIST,
  IDashboardList
> = createAction(
  ACTION_TYPES.GO_TO_CUSTOM_LIST,
  createPayload<IDashboardList>(),
);

export const deleteDashboardDeployOnlyListAction: CreateActionType<
  ACTION_TYPES.DELETE_DEPLOY_ONLY_LIST,
  IDashboardDeleteEntity
> = createAction(
  ACTION_TYPES.DELETE_DEPLOY_ONLY_LIST,
  createPayload<IDashboardDeleteEntity>(),
);
export const deleteDashboardDeployOnlyListErrorAction: CreateActionType<
  ACTION_TYPES.DELETE_DEPLOY_ONLY_LIST_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.DELETE_DEPLOY_ONLY_LIST_ERROR,
  createPayload<IServerError>(),
);
export const deleteDashboardDeployOnlyListSuccessAction: ActionCreator<
  ACTION_TYPES.DELETE_DEPLOY_ONLY_LIST_SUCCESS,
  () => TypedAction<ACTION_TYPES.DELETE_DEPLOY_ONLY_LIST_SUCCESS>
> = createAction(ACTION_TYPES.DELETE_DEPLOY_ONLY_LIST_SUCCESS);

export const assignDashboardDeployOnlyListAction: CreateActionType<
  ACTION_TYPES.ASSIGN_DEPLOY_ONLY_LIST,
  IListAssign
> = createAction(
  ACTION_TYPES.ASSIGN_DEPLOY_ONLY_LIST,
  createPayload<IListAssign>(),
);
export const assignDashboardDeployOnlyListErrorAction: CreateActionType<
  ACTION_TYPES.ASSIGN_DEPLOY_ONLY_LIST_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.ASSIGN_DEPLOY_ONLY_LIST_ERROR,
  createPayload<IServerError>(),
);
export const assignDashboardDeployOnlyListSuccessAction: ActionCreator<
  ACTION_TYPES.ASSIGN_DEPLOY_ONLY_LIST_SUCCESS,
  () => TypedAction<ACTION_TYPES.ASSIGN_DEPLOY_ONLY_LIST_SUCCESS>
> = createAction(ACTION_TYPES.ASSIGN_DEPLOY_ONLY_LIST_SUCCESS);

export const calculateDashboardDeployOnlyListUpdateAction: CreateActionType<
  ACTION_TYPES.CALCULATE_UPDATE_DEPLOY_ONLY,
  IListCalculatedData
> = createAction(
  ACTION_TYPES.CALCULATE_UPDATE_DEPLOY_ONLY,
  createPayload<IListCalculatedData>(),
);
export const calculateDashboardDeployOnlyListUpdateSuccessAction: CreateActionType<
  ACTION_TYPES.CALCULATE_UPDATE_DEPLOY_ONLY_SUCCESS,
  IListCalculatedData
> = createAction(
  ACTION_TYPES.CALCULATE_UPDATE_DEPLOY_ONLY_SUCCESS,
  createPayload<IListCalculatedData>(),
);

export const exportDashboardDeployOnlyListAction: CreateActionType<
  ACTION_TYPES.EXPORT_DEPLOY_ONLY_LIST,
  IExportDashboardList
> = createAction(
  ACTION_TYPES.EXPORT_DEPLOY_ONLY_LIST,
  createPayload<IExportDashboardList>(),
);

// export const showCDLListChangeStatusPopUpAction: CreateActionType<
//   ACTION_TYPES.SHOW_CDL_LIST_CHANGE_STATUS_POP_UP,
//   ICDLListChangeStatusPayload
// > = createAction(
//   ACTION_TYPES.SHOW_CDL_LIST_CHANGE_STATUS_POP_UP,
//   createPayload<ICDLListChangeStatusPayload>(),
// );

// export const changeCDLListStatusAction: CreateActionType<
//   ACTION_TYPES.CDL_LIST_CHANGE_STATUS,
//   number
// > = createAction(ACTION_TYPES.CDL_LIST_CHANGE_STATUS, createPayload<number>());
// export const changeCDLListStatusErrorAction: CreateActionType<
//   ACTION_TYPES.CDL_LIST_CHANGE_STATUS_ERROR,
//   IServerError
// > = createAction(
//   ACTION_TYPES.CDL_LIST_CHANGE_STATUS_ERROR,
//   createPayload<IServerError>(),
// );
// export const changeCDLListStatusSuccessAction: CreateActionType<
//   ACTION_TYPES.CDL_LIST_CHANGE_STATUS_SUCCESS,
//   number
// > = createAction(
//   ACTION_TYPES.CDL_LIST_CHANGE_STATUS_SUCCESS,
//   createPayload<number>(),
// );

// export const refreshCDLListAction: CreateActionType<
//   ACTION_TYPES.CDL_LIST_REFRESH,
//   number
// > = createAction(ACTION_TYPES.CDL_LIST_REFRESH, createPayload<number>());
// export const refreshCDLListErrorAction: CreateActionType<
//   ACTION_TYPES.CDL_LIST_REFRESH_ERROR,
//   IServerError
// > = createAction(
//   ACTION_TYPES.CDL_LIST_REFRESH_ERROR,
//   createPayload<IServerError>(),
// );
// export const refreshCDLListSuccessAction: ActionCreator<
//   ACTION_TYPES.CDL_LIST_REFRESH_SUCCESS,
//   () => TypedAction<ACTION_TYPES.CDL_LIST_REFRESH_SUCCESS>
// > = createAction(ACTION_TYPES.CDL_LIST_REFRESH_SUCCESS);

export const listenSocketCountsRefreshed: ActionCreator<
  ACTION_TYPES.LISTEN_SOCKET_COUNTS_REFRESHED,
  () => TypedAction<ACTION_TYPES.LISTEN_SOCKET_COUNTS_REFRESHED>
> = createAction(ACTION_TYPES.LISTEN_SOCKET_COUNTS_REFRESHED);

export const resetDashboardDeployOnlyListsAction: ActionCreator<
  ACTION_TYPES.RESET_DEPLOY_ONLY_LISTS,
  () => TypedAction<ACTION_TYPES.RESET_DEPLOY_ONLY_LISTS>
> = createAction(ACTION_TYPES.RESET_DEPLOY_ONLY_LISTS);
