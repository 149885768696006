import {
  DASHBOARD_VIEW_TYPE,
  IFiltersResult,
} from "@modules/dashboard/interfaces";
import { ICDLLastPages } from "@shared/modules/cdl-last-activities/interfaces";

import { MANAGE_ACCOUNT_LISTS } from "@shared/constants/data/manage-account-lists";
import { SESSION_STORAGE_FIELDS as fields } from "@shared/constants/session-storage-fields";

export class SessionStorageService {
  private static _set<T>(
    name: Partial<fields>,
    value: T,
    storage: Storage = sessionStorage,
  ): void {
    storage.setItem(name, JSON.stringify(value));
  }

  private static _get<T>(name: string, storage: Storage = sessionStorage): T {
    const itemValue: string = storage.getItem(name);

    let value: any;

    try {
      value = JSON.parse(itemValue);
    } catch (e) {
      value = itemValue || null;
    }

    return value;
  }

  private static remove(name: string, storage: Storage = sessionStorage): void {
    storage.removeItem(name);
  }

  static clear(): void {
    Object.values(fields).forEach((name: string) =>
      SessionStorageService.remove(name),
    );
  }

  static get dashboardListsFiltersChecked(): IFiltersResult {
    return SessionStorageService._get(fields.dashboardListsFiltersChecked);
  }

  static set dashboardListsFiltersChecked(filters: IFiltersResult) {
    SessionStorageService._set(fields.dashboardListsFiltersChecked, filters);
  }

  static get dashboardDeployOnlyListsFiltersChecked(): IFiltersResult {
    return SessionStorageService._get(
      fields.dashboardDeployOnlyListsFiltersChecked,
    );
  }

  static set dashboardDeployOnlyListsFiltersChecked(filters: IFiltersResult) {
    SessionStorageService._set(
      fields.dashboardDeployOnlyListsFiltersChecked,
      filters,
    );
  }

  static get dashboardDeployOnlyListsViewType(): IFiltersResult {
    return SessionStorageService._get(fields.dashboardDeployOnlyListsViewType);
  }

  static set dashboardDeployOnlyListsViewType(filters: IFiltersResult) {
    SessionStorageService._set(
      fields.dashboardDeployOnlyListsViewType,
      filters,
    );
  }

  static get dashboardViewsFiltersChecked(): IFiltersResult {
    return SessionStorageService._get(fields.dashboardViewsFiltersChecked);
  }

  static set dashboardViewsFiltersChecked(filters: IFiltersResult) {
    SessionStorageService._set(fields.dashboardViewsFiltersChecked, filters);
  }

  static get showAccountLists(): MANAGE_ACCOUNT_LISTS {
    return SessionStorageService._get(fields.showAccountLists);
  }

  static set showAccountLists(state: MANAGE_ACCOUNT_LISTS) {
    SessionStorageService._set(fields.showAccountLists, state);
  }

  static get cdlLastCompletedPages(): ICDLLastPages {
    return SessionStorageService._get(fields.cdlLastCompletedPages) || [];
  }

  static set cdlLastCompletedPages(pages: ICDLLastPages) {
    SessionStorageService._set(fields.cdlLastCompletedPages, pages);
  }

  static get dashboardListsViewType(): DASHBOARD_VIEW_TYPE {
    return SessionStorageService._get(fields.dashboardListsViewType);
  }

  static set dashboardListsViewType(type: DASHBOARD_VIEW_TYPE) {
    SessionStorageService._set(fields.dashboardListsViewType, type);
  }

  static get dashboardViewsViewType(): DASHBOARD_VIEW_TYPE {
    return SessionStorageService._get(fields.dashboardViewsViewType);
  }

  static set dashboardViewsViewType(type: DASHBOARD_VIEW_TYPE) {
    SessionStorageService._set(fields.dashboardViewsViewType, type);
  }
}
