import {
  createSelector,
  MemoizedSelector,
  MemoizedSelectorWithProps,
} from "@ngrx/store";

import { CoreState } from "@core/store/reducers";
import { getExportingLists } from "@core/store/selectors/list-purchase.selector";
import {
  getRouterParams,
  getRouterUrl,
} from "@core/store/selectors/router.selector";
import { GetFromStateInSelectors } from "@shared/store/types/selector.types";
import { IDashboardListsModuleState } from "../reducers";
import * as reducer from "../reducers/dashboard-lists.reducer";
import { IDashboardListsState } from "../reducers/dashboard-lists.reducer";

import { IDashboardProductQueryParams } from "@modules/dashboard/interfaces";
import {
  IDashboardList,
  IDashboardLists,
  IPagination,
} from "@shared/interfaces/list";
import { IServerError } from "@shared/interfaces/server-error";

import { CORE_PATHS } from "@core/constants/core-paths";

import {
  getCheckedFilters,
  getDashboardListsFilters,
  getIsHasCheckedFilters,
  getShowAccountLists,
} from "./dashboard-lists-filter.selector";
import {
  getDashboardListsQuery,
  getIsHasSearch,
} from "./dashboard-lists-search.selector";
import {
  dashboardListsSortState,
  getDashboardListsSortBy,
  getDashboardListsSortDirection,
} from "./dashboard-lists-sorting.selector";
import { getDashboardListsViewType } from "./dashboard-lists-view.selector";
import { getDashboardListsModuleState } from "./index";

// dashboard lists;
export const dashboardListsState: GetFromStateInSelectors<
  CoreState,
  IDashboardListsState
> = createSelector(
  getDashboardListsModuleState,
  (state: IDashboardListsModuleState) => state.dashboardLists,
);

export const getDashboardLists: MemoizedSelector<CoreState, IDashboardLists> =
  createSelector(dashboardListsState, getExportingLists, reducer.lists);
export const getDashboardListById: MemoizedSelectorWithProps<
  CoreState,
  { readonly listId?: number },
  IDashboardList
> = createSelector(getDashboardLists, reducer.dashboardListById);

export const getHasLists: MemoizedSelector<CoreState, boolean> = createSelector(
  getDashboardLists,
  (_lists: IDashboardLists) => !!_lists.length,
);

export const getDashboardListsPagination: MemoizedSelector<
  CoreState,
  IPagination
> = createSelector(dashboardListsState, reducer.pagination);
export const getCurrentPage: MemoizedSelector<CoreState, number> =
  createSelector(getDashboardListsPagination, reducer.currentPage);
export const getDashboardListsForTable: MemoizedSelector<
  CoreState,
  IDashboardLists
> = createSelector(dashboardListsState, getCurrentPage, reducer.listsForTable);

export const isDashboardListsLoading: MemoizedSelector<CoreState, boolean> =
  createSelector(dashboardListsState, reducer.loading);

// renaming
export const isDashboardListRenaming: MemoizedSelector<CoreState, boolean> =
  createSelector(dashboardListsState, reducer.renaming);
export const getRenameDashboardListId: MemoizedSelector<CoreState, number> =
  createSelector(dashboardListsState, reducer.renameListId);
export const getRenameDashboardListError: MemoizedSelector<
  CoreState,
  IServerError
> = createSelector(dashboardListsState, reducer.renameListError);

// duplicating
export const isDashboardListDuplicating: MemoizedSelector<CoreState, boolean> =
  createSelector(dashboardListsState, reducer.duplicating);
export const getDuplicateDashboardListId: MemoizedSelector<CoreState, number> =
  createSelector(dashboardListsState, reducer.duplicateListId);

// deleting
export const isDashboardListDeleting: MemoizedSelector<CoreState, boolean> =
  createSelector(dashboardListsState, reducer.deleting);
export const getDeleteDashboardListId: MemoizedSelector<CoreState, number> =
  createSelector(dashboardListsState, reducer.deleteListId);

export const isDashboardListExporting: MemoizedSelector<CoreState, boolean> =
  createSelector(dashboardListsState, reducer.exporting);
export const getExportDashboardListId: MemoizedSelector<CoreState, number> =
  createSelector(dashboardListsState, reducer.exportListId);

// navigating
export const isDashboardListNavigating: MemoizedSelector<CoreState, boolean> =
  createSelector(dashboardListsState, reducer.navigating);
export const getNavigatingDashboardListId: MemoizedSelector<CoreState, number> =
  createSelector(dashboardListsState, reducer.navigatingListId);

export const getCDLListStatusChanging: MemoizedSelector<CoreState, boolean> =
  createSelector(dashboardListsState, reducer.cdlListStatusChanging);
export const getCDLListStatusChangeId: MemoizedSelector<CoreState, number> =
  createSelector(dashboardListsState, reducer.cdlListStatusChangeId);

// is list update
export const isListUpdating: MemoizedSelector<CoreState, boolean> =
  createSelector(
    isDashboardListRenaming,
    isDashboardListDuplicating,
    isDashboardListDeleting,
    isDashboardListNavigating,
    getCDLListStatusChanging,
    isDashboardListExporting,
    reducer.listUpdating,
  );

export const getUpdatingListId: MemoizedSelector<CoreState, number> =
  createSelector(
    getRenameDashboardListId,
    getDuplicateDashboardListId,
    getDeleteDashboardListId,
    getNavigatingDashboardListId,
    getCDLListStatusChangeId,
    getExportDashboardListId,
    reducer.updatingListId,
  );

// query
export const getDashboardListsQueryParams: MemoizedSelector<
  CoreState,
  IDashboardProductQueryParams
> = createSelector(
  getCheckedFilters,
  getDashboardListsSortDirection,
  getDashboardListsSortBy,
  getDashboardListsPagination,
  getDashboardListsQuery,
  getRouterUrl,
  reducer.listsQueryParams,
);

export const getDashboardListsLastUpdatedParamsWithSorting: MemoizedSelector<
  CoreState,
  IDashboardProductQueryParams
> = createSelector(
  getCheckedFilters,
  dashboardListsSortState,
  getDashboardListsQuery,
  getShowAccountLists,
  reducer.lastUpdatedCheckedFiltersWithSorting,
);

export const assignListLoading: MemoizedSelector<CoreState, boolean> =
  createSelector(dashboardListsState, reducer.assignLoading);
export const getAssignListLoaded: MemoizedSelector<CoreState, boolean> =
  createSelector(dashboardListsState, reducer.assignListLoaded);
export const getAssignListError: MemoizedSelector<CoreState, IServerError> =
  createSelector(dashboardListsState, reducer.assignListError);

export const getListEmptyDescription: MemoizedSelector<CoreState, string> =
  createSelector(
    getHasLists,
    getIsHasCheckedFilters,
    getIsHasSearch,
    reducer.listEmptyDescription,
  );

export const getIsShowSortDropDown: MemoizedSelector<CoreState, boolean> =
  createSelector(
    getDashboardListsViewType,
    getHasLists,
    getIsHasCheckedFilters,
    getIsHasSearch,
    reducer.isShowSortDropDown,
  );

export const getIsHasData: MemoizedSelector<CoreState, boolean> =
  createSelector(
    getHasLists,
    getIsHasCheckedFilters,
    getIsHasSearch,
    reducer.isHasData,
  );

export const getIsDashboardListsPage: MemoizedSelector<CoreState, boolean> =
  createSelector(getRouterUrl, (url: string): boolean =>
    url.includes(CORE_PATHS.DASHBOARD),
  );

export const getIsShowFilters: MemoizedSelector<CoreState, boolean> =
  createSelector(getDashboardListsFilters, reducer.isShowFilters);
