import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";

import { PopUpRef } from "../../models/pop-up-ref";

import { POP_UP_DATA } from "../../injection-tokens";

export interface IConfirmRefreshPopUpResult {
  answer: boolean;
  withNewRecords?: boolean;
}

export interface IConfirmRefreshPopUpData {
  addedNumber: number;
  updatedNumber: number;
  withAdditional: boolean;
  withoutAdditional: boolean;
  deployOnly?: boolean;
}

@Component({
  selector: "bl-confirm-refresh-pop-up-content",
  templateUrl: "./confirm-refresh-pop-up-content.component.html",
  styleUrls: ["./confirm-refresh-pop-up-content.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmRefreshPopUpContentComponent {
  constructor(
    private popUpRef: PopUpRef<
      ConfirmRefreshPopUpContentComponent,
      IConfirmRefreshPopUpResult
    >,
    @Inject(POP_UP_DATA) public data: IConfirmRefreshPopUpData,
  ) {}

  updateWithNewRecords(): void {
    this.popUpRef.close({ answer: true, withNewRecords: true });
  }

  updateWithoutNewRecords(): void {
    this.popUpRef.close({ answer: true, withNewRecords: false });
  }

  onClose(): void {
    this.popUpRef.close({ answer: false });
  }
}
