export enum SESSION_STORAGE_FIELDS {
  dashboardListsFiltersChecked = "dashboard-lists-filters-checked",
  dashboardDeployOnlyListsFiltersChecked = "dashboard-deploy-only-lists-filters-checked",
  dashboardViewsFiltersChecked = "dashboard-views-filters-checked",
  dashboardDeployOnlyListsViewType = "dashboard-deploy-only-views-filters-checked",
  showAccountLists = "showAccountLists",
  cdlLastCompletedPages = "cdlLastCompletedPages",
  dashboardListsViewType = "dashboard-lists-view-type",
  dashboardViewsViewType = "dashboard-views-view-type",
}
