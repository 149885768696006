import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/dashboard-lists.action";

import { SessionStorageService } from "@core/services/session-storage.service";

import { IDashboardList, IPagination } from "@shared/interfaces/list";
import { IServerError } from "@shared/interfaces/server-error";
import {
  DASHBOARD_VIEW_TYPE,
  IDashboardProductQueryParams,
  IFilters,
  IFiltersResult,
  IListAssign,
} from "../../../dashboard/interfaces";

import { IS_SHOWED_NOT_ALL_PRODUCTS } from "@modules/dashboard-lists/constants";
import { MANAGE_ACCOUNT_LISTS } from "@shared/constants/data/manage-account-lists";
import {
  DASHBOARD_EMPTY_LIST_DESCRIPTION,
  DASHBOARD_NO_CHANGES_DESCRIPTION,
  DEFAULT_FILTER_QUERY_PARAMS,
} from "../../../dashboard/constants/dashboard";
import {
  DEFAULT_PAGINATION,
  PAGINATION_TABLE,
} from "../../../dashboard/constants/default-pagination";

import { IDashboardListsSortingState } from "./dashboard-lists-sorting.reducer";

export interface IDashboardListsState {
  lists: IDashboardList[] | null;
  pagination: IPagination | null;

  loaded: boolean;
  loading: boolean;
  error: IServerError | null;

  renaming: boolean;
  renameListId: number;
  renameListError: IServerError | null;

  duplicating: boolean;
  duplicateListId: number;
  duplicateListError: IServerError | null;

  createCustomListError: IServerError | null;

  deleting: boolean;
  deleteListId: number;
  deleteListError: IServerError | null;

  exporting: boolean;
  exportListId: number;

  navigating: boolean;
  navigatingListId: number;

  assignLoading: boolean;
  assignListLoaded: boolean;
  assignListData: IListAssign;
  assignListError: IServerError | null;

  statusChangeId: number | null;
  statusChangeError: IServerError | null;
  statusChanging: boolean;
}

const initialState: IDashboardListsState = {
  lists: [],
  pagination: { ...DEFAULT_PAGINATION },

  loaded: false,
  loading: false,
  error: null,

  renaming: false,
  renameListId: null,
  renameListError: null,

  duplicating: false,
  duplicateListId: null,
  duplicateListError: null,

  createCustomListError: null,

  deleting: false,
  deleteListId: null,
  deleteListError: null,

  exporting: false,
  exportListId: null,

  navigating: false,
  navigatingListId: null,

  assignLoading: false,
  assignListLoaded: false,
  assignListData: null,
  assignListError: null,

  statusChangeId: null,
  statusChangeError: null,
  statusChanging: false,
};

const getDashboardLists: OnReducer<IDashboardListsState, ActionType<any>> = (
  state: IDashboardListsState,
) => ({
  ...state,
  loading: true,
  error: null,
});

const getDashboardListsError: OnReducer<
  IDashboardListsState,
  ActionType<Payload<IServerError>>
> = (state: IDashboardListsState, { payload }: Payload<IServerError>) => ({
  ...state,
  loading: false,
  error: { ...payload },
});

const getDashboardListsSuccess: OnReducer<
  IDashboardListsState,
  ActionType<Payload<any>>
> = (state: IDashboardListsState, { payload }: Payload<any>) => ({
  ...state,
  lists: payload.lists,
  pagination: {
    ...state.pagination,
    moreAvailable: payload.pagination.moreAvailable,
    total: payload.pagination.total,
  },
  loaded: true,
  loading: false,
});

const getDashboardListsNextPage: OnReducer<
  IDashboardListsState,
  ActionType<Payload<any>>
> = (state: IDashboardListsState, { payload }: Payload<any>) => ({
  ...state,
  pagination: {
    ...state.pagination,
    offset: state.pagination.offset + (payload.limit || state.pagination.limit),
  },
  loading: true,
});

const getDashboardListsNextPageSuccess: OnReducer<
  IDashboardListsState,
  ActionType<Payload<any>>
> = (state: IDashboardListsState, { payload }: Payload<any>) => ({
  ...state,
  lists: [...state.lists, ...payload.lists],
  pagination: {
    ...state.pagination,
    moreAvailable: payload.pagination.moreAvailable,
    total: payload.pagination.total,
  },
  loading: false,
});

const changePageWithoutLoadDataDashboardLists: OnReducer<
  IDashboardListsState,
  ActionType<Payload<any>>
> = (state: IDashboardListsState, { payload }: Payload<any>) => ({
  ...state,
  pagination: {
    ...state.pagination,
    offset: (payload - 1) * PAGINATION_TABLE.perPage,
  },
});

const changePageDashboardLists: OnReducer<
  IDashboardListsState,
  ActionType<Payload<any>>
> = (state: IDashboardListsState, { payload }: Payload<any>) => ({
  ...state,
  pagination: {
    ...state.pagination,
    offset: (payload - 1) * PAGINATION_TABLE.perPage,
  },
  loading: true,
});

const changePageDashboardListsSuccess: OnReducer<
  IDashboardListsState,
  ActionType<Payload<any>>
> = (state: IDashboardListsState, { payload }: Payload<any>) => ({
  ...state,
  pagination: {
    ...state.pagination,
    moreAvailable: payload.pagination.moreAvailable,
    total: payload.pagination.total,
  },
  lists: [...state.lists, ...payload.lists],
  loading: false,
});

const reloadDashboardLists: OnReducer<IDashboardListsState, ActionType<any>> = (
  state: IDashboardListsState,
) => ({
  ...state,
  loading: true,
  pagination: {
    ...state.pagination,
    limit: state.pagination.offset + state.pagination.limit,
    offset: DEFAULT_PAGINATION.offset,
  },
});

const reloadDashboardListsError: OnReducer<
  IDashboardListsState,
  ActionType<Payload<any>>
> = (state: IDashboardListsState, { payload }: Payload<any>) => ({
  ...state,
  loading: false,
  error: { ...payload },
  pagination: {
    ...payload.pagination,
    offset:
      SessionStorageService.dashboardListsViewType ===
      DASHBOARD_VIEW_TYPE.PRODUCT
        ? state.pagination.limit - DEFAULT_PAGINATION.limit || 0
        : state.pagination.offset,
    limit: DEFAULT_PAGINATION.limit,
  },
  exporting: false,
  exportListId: null,
});

const reloadDashboardListsSuccess: OnReducer<
  IDashboardListsState,
  ActionType<Payload<any>>
> = (state: IDashboardListsState, { payload }: Payload<any>) => ({
  ...state,
  loading: false,
  lists: [...payload.lists],
  pagination: {
    ...payload.pagination,
    offset:
      SessionStorageService.dashboardListsViewType ===
      DASHBOARD_VIEW_TYPE.PRODUCT
        ? state.pagination.limit - DEFAULT_PAGINATION.limit || 0
        : state.pagination.offset,
    limit: DEFAULT_PAGINATION.limit,
    moreAvailable: payload.pagination.moreAvailable,
  },
  exporting: false,
  exportListId: null,
});

const listNavigate: OnReducer<
  IDashboardListsState,
  ActionType<Payload<any>>
> = (state: IDashboardListsState, { payload }: Payload<any>) => ({
  ...state,
  navigating: true,
  navigatingListId: payload.productId,
});

const listNavigateCancel: OnReducer<IDashboardListsState, ActionType<any>> = (
  state: IDashboardListsState,
) => ({
  ...state,
  navigating: false,
  navigatingListId: null,
});

const renameDashboardList: OnReducer<
  IDashboardListsState,
  ActionType<Payload<any>>
> = (state: IDashboardListsState, { payload }: Payload<any>) => ({
  ...state,
  renaming: true,
  renameListId: payload.listId,
  renameListError: null,
});

const renameDashboardListError: OnReducer<
  IDashboardListsState,
  ActionType<Payload<IServerError>>
> = (state: IDashboardListsState, { payload }: Payload<IServerError>) => ({
  ...state,
  renaming: false,
  renameListError: { ...payload },
});

const renameDashboardListSuccess: OnReducer<
  IDashboardListsState,
  ActionType<any>
> = (state: IDashboardListsState) => ({
  ...state,
  renaming: false,
  renameListId: null,
  renameListError: null,
});

const renameDashboardListCancel: OnReducer<
  IDashboardListsState,
  ActionType<any>
> = (state: IDashboardListsState) => ({
  ...state,
  renaming: false,
  renameListError: null,
});

const duplicateDashboardList: OnReducer<
  IDashboardListsState,
  ActionType<Payload<any>>
> = (state: IDashboardListsState, { payload }: Payload<any>) => ({
  ...state,
  duplicating: true,
  duplicateListId: payload.listId,
  duplicateListError: null,
});

const duplicateDashboardListError: OnReducer<
  IDashboardListsState,
  ActionType<Payload<IServerError>>
> = (state: IDashboardListsState, { payload }: Payload<IServerError>) => ({
  ...state,
  duplicating: false,
  duplicateListError: { ...payload },
});

const createCustomListError: OnReducer<
  IDashboardListsState,
  ActionType<Payload<IServerError>>
> = (state: IDashboardListsState, { payload }: Payload<IServerError>) => ({
  ...state,
  createCustomListError: { ...payload },
});

const duplicateDashboardSuccessList: OnReducer<
  IDashboardListsState,
  ActionType<any>
> = (state: IDashboardListsState) => ({
  ...state,
  duplicating: false,
  duplicateListId: null,
  duplicateListError: null,
});

const deleteDashboardList: OnReducer<
  IDashboardListsState,
  ActionType<Payload<any>>
> = (state: IDashboardListsState, { payload }: Payload<any>) => ({
  ...state,
  deleting: true,
  deleteListId: payload.listId,
  deleteListError: null,
});

const deleteDashboardListError: OnReducer<
  IDashboardListsState,
  ActionType<Payload<IServerError>>
> = (state: IDashboardListsState, { payload }: Payload<IServerError>) => ({
  ...state,
  deleting: false,
  deleteListError: { ...payload },
});

const deleteDashboardListSuccess: OnReducer<
  IDashboardListsState,
  ActionType<any>
> = (state: IDashboardListsState) => ({
  ...state,
  deleting: false,
  deleteListId: null,
  deleteListError: null,
});

const exportDashboardList: OnReducer<
  IDashboardListsState,
  ActionType<Payload<any>>
> = (state: IDashboardListsState, { payload }: Payload<any>) => ({
  ...state,
  exporting: true,
  exportListId: payload,
});

const assignDashboardList: OnReducer<
  IDashboardListsState,
  ActionType<Payload<any>>
> = (state: IDashboardListsState, { payload }: Payload<any>) => ({
  ...state,
  assignLoading: true,
  assignListLoaded: false,
  assignListData: { ...payload },
  assignListError: null,
});

const assignDashboardListError: OnReducer<
  IDashboardListsState,
  ActionType<Payload<IServerError>>
> = (state: IDashboardListsState, { payload }: Payload<IServerError>) => ({
  ...state,
  assignLoading: false,
  assignListLoaded: false,
  assignListError: { ...payload },
});

const assignDashboardListSuccess: OnReducer<
  IDashboardListsState,
  ActionType<any>
> = (state: IDashboardListsState) => ({
  ...state,
  assignLoading: false,
  assignListLoaded: true,
  assignListData: null,
  assignListError: null,
});

const calculateDashboardListUpdate: OnReducer<
  IDashboardListsState,
  ActionType<Payload<any>>
> = (
  state: IDashboardListsState,
  { payload: { listingId, uploadedPercent } }: Payload<any>,
) => ({
  ...state,
  lists: state.lists.map((_list: IDashboardList) =>
    _list.id === listingId
      ? {
          ..._list,
          maxNamesCalculationPercent: uploadedPercent,
        }
      : _list,
  ),
});

const changeCDLListStatus: OnReducer<
  IDashboardListsState,
  ActionType<Payload<any>>
> = (state: IDashboardListsState, { payload }: Payload<any>) => ({
  ...state,
  statusChanging: true,
  statusChangeId: payload.listId,
  statusChangeError: null,
});

const changeCDLListStatusError: OnReducer<
  IDashboardListsState,
  ActionType<Payload<IServerError>>
> = (state: IDashboardListsState, { payload }: Payload<IServerError>) => ({
  ...state,
  statusChanging: false,
  statusChangeError: payload,
});

const changeCDLListStatusSuccess: OnReducer<
  IDashboardListsState,
  ActionType<any>
> = (state: IDashboardListsState) => ({
  ...state,
  statusChanging: false,
  statusChangeId: null,
  statusChangeError: null,
});

const resetDashboardLists: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IDashboardListsState> =
  createReducer<IDashboardListsState>(
    initialState,

    on(actions.getDashboardListsAction, getDashboardLists),
    on(actions.getDashboardListsErrorAction, getDashboardListsError),
    on(actions.getDashboardListsSuccessAction, getDashboardListsSuccess),

    on(actions.getDashboardListsNextPageAction, getDashboardListsNextPage),
    on(
      actions.getDashboardListsNextPageSuccessAction,
      getDashboardListsNextPageSuccess,
    ),

    on(
      actions.changePageWithoutLoadDataDashboardListsAction,
      changePageWithoutLoadDataDashboardLists,
    ),
    on(actions.changePageDashboardListsAction, changePageDashboardLists),
    on(
      actions.changePageDashboardListsSuccessAction,
      changePageDashboardListsSuccess,
    ),

    on(actions.reloadDashboardListsAction, reloadDashboardLists),
    on(actions.reloadDashboardListsErrorAction, reloadDashboardListsError),
    on(actions.reloadDashboardListsSuccessAction, reloadDashboardListsSuccess),

    on(actions.listNavigateAction, listNavigate),
    on(actions.listNavigateCancelAction, listNavigateCancel),

    on(actions.renameDashboardListAction, renameDashboardList),
    on(actions.renameDashboardListErrorAction, renameDashboardListError),
    on(actions.renameDashboardListSuccessAction, renameDashboardListSuccess),
    on(actions.renameDashboardListCancelAction, renameDashboardListCancel),

    on(actions.duplicateDashboardListAction, duplicateDashboardList),
    on(actions.duplicateDashboardListErrorAction, duplicateDashboardListError),
    on(
      actions.duplicateDashboardSuccessListAction,
      duplicateDashboardSuccessList,
    ),

    on(actions.createCustomListErrorAction, createCustomListError),

    on(actions.deleteDashboardListAction, deleteDashboardList),
    on(actions.deleteDashboardListErrorAction, deleteDashboardListError),
    on(actions.deleteDashboardListSuccessAction, deleteDashboardListSuccess),

    on(actions.exportDashboardListAction, exportDashboardList),

    on(actions.assignDashboardListAction, assignDashboardList),
    on(actions.assignDashboardListErrorAction, assignDashboardListError),
    on(actions.assignDashboardListSuccessAction, assignDashboardListSuccess),

    on(
      actions.calculateDashboardListUpdateAction,
      calculateDashboardListUpdate,
    ),
    on(
      actions.calculateDashboardListUpdateSuccessAction,
      calculateDashboardListUpdate,
    ),

    on(actions.changeCDLListStatusAction, changeCDLListStatus),
    on(actions.changeCDLListStatusErrorAction, changeCDLListStatusError),
    on(actions.changeCDLListStatusSuccessAction, changeCDLListStatusSuccess),

    on(actions.resetDashboardListsAction, resetDashboardLists),
  );

export function dashboardListsReducer(
  state: IDashboardListsState,
  action: Action,
): IDashboardListsState {
  return reducer(state, action);
}

export const lists: GetFromState<
  IDashboardList[],
  IDashboardListsState,
  { [key: string]: number }
> = (
  state: IDashboardListsState,
  exportingLists: { [key: string]: number },
): IDashboardList[] =>
  state.lists.map((list: IDashboardList) =>
    typeof exportingLists[list.id] !== "undefined"
      ? {
          ...list,
          exportCalculatingPercent: exportingLists[list.id],
        }
      : list,
  );

export const dashboardListById: GetFromState<
  IDashboardList,
  IDashboardList[],
  { listId: number }
> = (
  _lists: IDashboardList[],
  { listId }: { listId: number },
): IDashboardList =>
  _lists && _lists.find((_list: IDashboardList) => _list.id === listId);
export const pagination: GetFromState<IPagination, IDashboardListsState> = (
  state: IDashboardListsState,
): IPagination => state.pagination;
export const currentPage: GetFromState<number, IPagination> = (
  _pagination: IPagination,
): number => {
  const { offset, limit }: IPagination = _pagination;

  return Math.ceil(offset / limit) + 1;
};

export const listsForTable: GetFromState<
  IDashboardList[],
  IDashboardListsState,
  number
> = (state: IDashboardListsState, _currentPage: number): IDashboardList[] => {
  return (
    state.lists &&
    state.lists.slice(
      state.pagination.offset,
      state.pagination.offset + state.pagination.limit,
    )
  );
};

export const loaded: GetFromState<boolean, IDashboardListsState> = (
  state: IDashboardListsState,
): boolean => state.loaded;
export const loading: GetFromState<boolean, IDashboardListsState> = (
  state: IDashboardListsState,
): boolean => state.loading;

export const renaming: GetFromState<boolean, IDashboardListsState> = (
  state: IDashboardListsState,
): boolean => state.renaming;
export const renameListId: GetFromState<number, IDashboardListsState> = (
  state: IDashboardListsState,
): number => state.renameListId;
export const renameListError: GetFromState<
  IServerError,
  IDashboardListsState
> = (state: IDashboardListsState): IServerError => state.renameListError;

export const duplicating: GetFromState<boolean, IDashboardListsState> = (
  state: IDashboardListsState,
): boolean => state.duplicating;
export const duplicateListId: GetFromState<number, IDashboardListsState> = (
  state: IDashboardListsState,
): number => state.duplicateListId;

export const deleting: GetFromState<boolean, IDashboardListsState> = (
  state: IDashboardListsState,
): boolean => state.deleting;
export const deleteListId: GetFromState<number, IDashboardListsState> = (
  state: IDashboardListsState,
): number => state.deleteListId;

export const exporting: GetFromState<boolean, IDashboardListsState> = (
  state: IDashboardListsState,
): boolean => state && state.exporting;
export const exportListId: GetFromState<number, IDashboardListsState> = (
  state: IDashboardListsState,
): number => state && state.exportListId;

export const navigating: GetFromState<boolean, IDashboardListsState> = (
  state: IDashboardListsState,
): boolean => state.navigating;
export const navigatingListId: GetFromState<number, IDashboardListsState> = (
  state: IDashboardListsState,
): number => state.navigatingListId;

export const assignLoading: GetFromState<boolean, IDashboardListsState> = (
  state: IDashboardListsState,
): boolean => state.assignLoading;
export const assignListLoaded: GetFromState<boolean, IDashboardListsState> = (
  state: IDashboardListsState,
): boolean => state.assignListLoaded;
export const assignListError: GetFromState<
  IServerError,
  IDashboardListsState
> = (state: IDashboardListsState): IServerError => state.assignListError;

export const cdlListStatusChanging: GetFromState<
  boolean,
  IDashboardListsState
> = (state: IDashboardListsState): boolean => state && state.statusChanging;
export const cdlListStatusChangeId: GetFromState<
  number | null,
  IDashboardListsState
> = (state: IDashboardListsState): number | null =>
  state && state.statusChangeId;

export const listUpdating: GetFromState<
  boolean,
  boolean,
  boolean,
  boolean,
  boolean,
  boolean,
  boolean
> = (
  _renaming: boolean,
  _duplicating: boolean,
  _deleting: boolean,
  _navigating: boolean,
  _statusChanging: boolean,
  _exporting: boolean,
): boolean => {
  return (
    _renaming ||
    _duplicating ||
    _deleting ||
    _navigating ||
    _statusChanging ||
    _exporting
  );
};

export const updatingListId: GetFromState<
  number,
  number,
  number,
  number,
  number,
  number,
  number
> = (
  _renameListId: number,
  _duplicateListId: number,
  _deleteListId: number,
  _navigatingListId: number,
  _statusChangeId: number,
  _exportingListId: number,
): number => {
  return (
    _renameListId ||
    _duplicateListId ||
    _deleteListId ||
    _navigatingListId ||
    _statusChangeId ||
    _exportingListId
  );
};

export const listsQueryParams: GetFromState<
  IDashboardProductQueryParams,
  IFiltersResult,
  string,
  string,
  IPagination,
  string,
  string
> = (
  filters: IFiltersResult,
  sortDirection: string,
  sortBy: string,
  _pagination: IPagination,
  query: string,
  url: string,
): IDashboardProductQueryParams => {
  const { limit, offset }: IPagination = _pagination;

  const queryParams: IDashboardProductQueryParams = {
    ...filters,
    sortDirection,
    sortBy,
    limit,
    offset,
  };

  if (query) {
    queryParams["query"] = query;
  }

  queryParams["entityType"] = queryParams["entityType"]?.length
    ? queryParams["entityType"]
    : DEFAULT_FILTER_QUERY_PARAMS.entityType;

  return queryParams;
};

export const lastUpdatedCheckedFiltersWithSorting: GetFromState<
  IDashboardProductQueryParams,
  IFiltersResult,
  IDashboardListsSortingState,
  string,
  MANAGE_ACCOUNT_LISTS,
  string
> = (
  checkedFilters: IFiltersResult,
  dashboardListsSortState: IDashboardListsSortingState,
  query: string,
  showAccountLists: MANAGE_ACCOUNT_LISTS,
): IDashboardProductQueryParams => {
  let filters: IDashboardProductQueryParams = {
    ...dashboardListsSortState,
    ...checkedFilters,
  };

  if (query && query.length) {
    filters = { ...filters, query };
  }

  if (typeof showAccountLists === "number") {
    filters = { ...filters, showAccountLists };
  }

  return filters;
};

export const listEmptyDescription: GetFromState<
  string,
  boolean,
  boolean,
  boolean
> = (
  hasLists: boolean,
  isHasCheckedFilters: boolean,
  isHasSearch: boolean,
): string => {
  if (isHasCheckedFilters || isHasSearch) {
    return DASHBOARD_NO_CHANGES_DESCRIPTION;
  }

  return DASHBOARD_EMPTY_LIST_DESCRIPTION;
};

export const isShowSortDropDown: GetFromState<
  boolean,
  DASHBOARD_VIEW_TYPE,
  boolean,
  boolean,
  boolean
> = (
  viewType: DASHBOARD_VIEW_TYPE,
  isHasLists: boolean,
  isHasCheckedFilters: boolean,
  isHasSearch: boolean,
): boolean => {
  return (
    viewType === DASHBOARD_VIEW_TYPE.PRODUCT &&
    (isHasLists || isHasCheckedFilters || isHasSearch)
  );
};

export const isHasData: GetFromState<boolean, boolean, boolean, boolean> = (
  hasLists: boolean,
  isHasCheckedFilters: boolean,
  isHasSearch: boolean,
): boolean => {
  return hasLists || isHasCheckedFilters || isHasSearch;
};

export const isShowFilters: GetFromState<boolean, IFilters> = (
  filters: IFilters,
): boolean =>
  (filters && filters.count >= 2) ||
  IS_SHOWED_NOT_ALL_PRODUCTS.includes(SessionStorageService.showAccountLists);
